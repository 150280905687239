@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');

:root {
  --app-header-height: 50px;
  --top-bar-height: 50px;
}

body {
  margin: 0;
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
#root,
body {
  height: 100%;
  min-height: calc(100vh);
  overflow-x: hidden;
}

.loader-transition-enter {
  opacity: 0;
}

.loader-transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.loader-transition-exit {
  opacity: 1;
}

.loader-transition-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.date-picker-popover {
  width: max-content !important;
  overflow-y: auto;
  max-height: calc(90vh - 52px);
  padding: 0;
  border: none;

  @media (max-width: 500px) {
    width: 310px !important;
  }
}

.hidden {
  display: none;
}

.link {
  all: unset;
  color: #006fff;
  font-size: 12px;
  cursor: pointer;
}

.link:hover {
  color: #0056c7;
}

.link:active {
  color: #003c9e;
}

.modal {
  width: 888px;
  height: 576px;
  border-radius: 16px;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.import-modal {
  width: 888px;
  height: 576px;
  border-radius: 16px;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding: 0;
}

.import-modal > div:nth-child(2) {
  height: 100%;
  overflow: hidden;
  padding: 0;
}

.modal > div {
  height: 100%;
  overflow: hidden;
}

.modal-small > div {
  height: 100%;
}

.modal-medium > div {
  height: 100%;
}

.modal-tiny > div {
  height: 100%;
}

.modal-medium {
  width: 666px;
  height: 484px;
  border-radius: 16px;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.modal-small {
  width: 444px;
  height: 576px;
  border-radius: 16px;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  overflow: visible;
}

.modal-small-auto {
  display: flex;
  justify-content: center;
  width: 444px;
  height: auto;
  border-radius: 16px;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  overflow: visible;
}

.modal--smal__header {
  width: 430px;
}

.modal--smal__header div {
  display: flex;
  justify-content: center;
}

.modal--smal__footer > div {
  margin-right: 12px;
}

.modal-tiny {
  height: 180px;
  width: 444px;
  border-radius: 16px;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  overflow: visible;
}

.modal-edit-role {
  height: 200px;
  width: 444px;
  border-radius: 16px;
  box-shadow: 0px 12px 48px rgba(0, 0, 0, 0.15);
  overflow: visible;
}

.modal-edit-role > div {
  height: 100%;
}

.modal-company-profile {
  width: 730px;
  height: 500px;
  padding: 28px;
  align-items: center;
}

.popover {
  padding: 10px 0;
  width: 160px;
  overflow: hidden;
}

.popover-big {
  width: 256px;
  padding: 0;
}

.quote-filters {
  border-radius: 0px;
  height: calc(100vh - var(--app-header-height));
  transform: translate(0px, calc(100vh - var(--app-header-height))) !important;
}

.company_popover {
  width: 288px;
  padding: 24px;
}

.date-picker {
  height: auto;
  width: auto;
  max-height: unset;

  padding: 0;
  border-radius: 6px;
  border: none;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: 0 4px 20px 0 rgba(21, 23, 37, 0.2);
}

.option-container {
  background-color: white;
  overflow-y: auto;
}

.dropdown-options-container li {
  min-height: 20px !important;
  min-height: 0px !important;
}

.dropdown-class input {
  margin: 0;
  color: #006fff !important;
  -webkit-text-fill-color: #006fff !important;
}

.dropdown-class > div > div {
  display: flex;
  align-items: center;
  box-shadow: unset !important;
}

.dropdown-class > div > div > div > div > div svg {
  margin-left: 5px;
  width: 6px;
  color: #006fff !important;
}

.dropdown-class > div > div > div > div > div:first-child svg {
  width: 12px;
}

.dropdown-remove-bar > div > div {
  box-shadow: unset !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.hide-location-dropdown {
  display: none;
  background-color: #003c9e;
}

.loading {
  opacity: 0.5;
}

.text-decor {
  text-decoration: none;
}

.no-resize-x {
  resize: vertical;
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}

.fit-width {
  width: 100%;
  width: -webkit-fill-available;
  width: -moz-available;
}

.break-word {
  word-wrap: break-word;
}
